import { Timestamp } from '@firebase/firestore'
import { createFillArray } from '.'
import type {
  FirestoreLadder,
  PlayerId,
  StorageLadder,
  StorageTier,
} from '../types'

export const NUM_TIERS = 5
export const NUM_PLAYERS_IN_LADDER = NUM_TIERS * NUM_TIERS

export const getBlankStorageTier = (index: number): StorageTier =>
  createFillArray(2 * index + 1, (): PlayerId => '')

export const getBlankStorageLadder = (): StorageLadder => ({
  tiers: createFillArray(NUM_TIERS, getBlankStorageTier),
})

export const fromFirestoreLadder = (
  ladder: FirestoreLadder,
): StorageLadder => ({
  tiers: JSON.parse(ladder.tiers) as StorageTier[],
  from: ladder.from,
})

export const fromFirestoreDate = (firestoreDate: Timestamp) =>
  firestoreDate.toDate().toISOString()

export const isEmptyStorageTier = (tier: StorageTier): boolean =>
  tier.every((id) => !id)

export const isEmptyStorageTiers = (tiers: StorageTier[]): boolean =>
  tiers.every(isEmptyStorageTier)

export const isFullStorageTier = (tier: StorageTier): boolean =>
  tier.every((id) => !!id)

export const isFullStorageTiers = (tiers: StorageTier[]): boolean =>
  tiers.every(isFullStorageTier)

export const sortTier = (tier: StorageTier): StorageTier =>
  [...tier].sort((idA, idB) => idB.localeCompare(idA))

export const getIds = (tiers: StorageTier[]): PlayerId[] =>
  tiers.flat().filter((id) => id)
