import { snakeCase } from 'snake-case'
import { getFullUrl } from '.'
import type {
  LadderId,
  Player,
  PlayerLadder,
  PlayerTier,
  SlotInfo,
  StorageTier,
} from '../types'
import { NUM_TIERS } from './storage'

const SLUG_REGEX = /^(\w*-)?(\w{20})$/

export const MAX_POPULARITY = NUM_TIERS + 1

export const genLadderSlug = ({
  id,
  title,
}: {
  id: LadderId
  title: PlayerLadder['title']
}) => {
  const snakeTitle = snakeCase(title)

  return snakeTitle ? `${snakeTitle}-${id}` : id
}

export const getLadderIdFromSlug = (slug: string): LadderId | undefined => {
  return slug.match(SLUG_REGEX)?.[2]
}

export const getLadderUrl = (
  {
    id,
    title,
  }: {
    id: LadderId
    title: PlayerLadder['title']
  },
  isFull = false,
) => {
  const path = `/l/${genLadderSlug({ id, title })}`

  return isFull ? getFullUrl(path) : path
}

export const getLadderDescription = (
  { tiers }: { tiers: PlayerTier[] },
  short = false,
): string => {
  const getPlayerName = short ? getPlayerNickname : getPlayerFullName
  const tierDescriptions = tiers.map((tier, index) => {
    const playerNames = tier.map(getPlayerName)

    return `${getTierTitle(index)}: ${playerNames.join(', ')}`
  })
  const description = tierDescriptions.join('\n')

  return short
    ? description
    : `Which NBA players are in the top 5 tiers?\n\n${description}`
}

const SOCIAL_IMAGE_LOOKUP = [
  {
    size: 150,
    y: 32,
    x: 755,
    spacing: 24,
  },
  {
    size: 125,
    y: 198,
    x: 621,
    spacing: 24,
  },
  {
    size: 105,
    y: 339,
    x: 520,
    spacing: 24,
  },
  {
    size: 85,
    y: 460,
    x: 461,
    spacing: 24,
  },
  {
    size: 76,
    y: 561,
    x: 412,
    spacing: 19,
  },
]
const escape = (text: string): string =>
  encodeURIComponent(encodeURIComponent(text))

export const getLadderImageUrl = ({
  tiers,
  title,
}: {
  tiers: PlayerTier[]
  title: PlayerLadder['title']
}): string => {
  const playerTransformations = tiers.flatMap((tier, tierIndex) => {
    const { size, x, y, spacing } = SOCIAL_IMAGE_LOOKUP[tierIndex]

    return tier.map((player, playerIndex) =>
      [
        `l_player-tiers:headshots:260x190:${player?.id}`,
        'g_north_west',
        `y_${y}`,
        `x_${x + playerIndex * (size + spacing)}`,
        'c_fill',
        `h_${size}`,
        `w_${size}`,
        'r_max',
      ].join(','),
    )
  })
  const textTransformation = [
    `l_text:roboto_48:${escape(title)}`,
    'g_south_west',
    'x_32',
    'y_32',
    'w_316',
    'h_204',
    'co_white',
    'c_fit',
  ].join(',')

  return [
    'https://res.cloudinary.com/benmvp/image/upload',
    ...playerTransformations,
    textTransformation,
    'v1/player-tiers/social-card-template_v59u92.jpg',
  ].join('/')
}

export const fromStorageTiers = (
  storageTiers: StorageTier[],
  allPlayers: Player[],
): PlayerTier[] => {
  const playerLookup = Object.fromEntries(
    allPlayers.map((player) => [player.id, player]),
  )

  return storageTiers.map((storageTier) =>
    storageTier.map(
      (playerId): SlotInfo => playerLookup[playerId] || undefined,
    ),
  )
}

export const toStorageTiers = (playerTiers: PlayerTier[]): StorageTier[] =>
  playerTiers.map((tier) => tier.map((player) => player?.id || ''))

export const isEmptyTier = (tier: PlayerTier): boolean =>
  tier.every((slot) => !slot)

export const isFullTier = (tier: PlayerTier): boolean =>
  tier.every((slot) => !!slot)

export const getTierTitle = (index: number): string =>
  index === 0 ? 'Top Tier' : `Tier ${index + 1}`

export const getAvailablePlayers = (
  players: Player[],
  tiers: StorageTier[],
): Player[] => {
  const pickedPlayerIds = new Set(tiers.flat())

  return players.filter((player) => !pickedPlayerIds.has(player.id))
}

export const sortPlayersByName = (players: Player[]) =>
  [...players].sort(
    (playerA, playerB) =>
      playerA.lastName.localeCompare(playerB.lastName) ||
      playerA.firstName.localeCompare(playerB.firstName),
  )

export const sortPlayersByPopularity = (players: Player[]) =>
  [...players].sort(
    (playerA, playerB) =>
      (playerA?.popularity ?? MAX_POPULARITY) -
        (playerB?.popularity ?? MAX_POPULARITY) ||
      playerA.lastName.localeCompare(playerB.lastName) ||
      playerA.firstName.localeCompare(playerB.firstName),
  )

export const filterPlayers = (players: Player[], filter: string): Player[] => {
  const filterLower = filter.toLocaleLowerCase()

  return players.filter(
    (player) =>
      player.firstName.toLocaleLowerCase().includes(filterLower) ||
      player.lastName.toLocaleLowerCase().includes(filterLower) ||
      player.nickname?.toLocaleLowerCase().includes(filterLower),
  )
}

export const getPlayerFullName = (player: SlotInfo): string =>
  player ? `${player.firstName} ${player.lastName}` : ''

export const getPlayerNickname = (player: SlotInfo): string =>
  player ? player.nickname || player.lastName : ''

export const getPlayerImageUrl = (player: SlotInfo): string =>
  `https://res.cloudinary.com/benmvp/image/upload/player-tiers/headshots/260x190/${
    player?.id || 'logoman'
  }.png`

export const getPlayerAvgTier = (player: SlotInfo): number | undefined =>
  player?.popularity ? Math.floor(player.popularity) : undefined
